import Cookie from 'js-cookie';

export function getCookie(cookie) {
  return Cookie.get(cookie, { domain: import.meta.env.VITE_APP_DOMAIN });
}

export function setCookie(cookie, value) {
  return Cookie.set(cookie, value, { domain: import.meta.env.VITE_APP_DOMAIN, expires: 90 });
}

export function removeCookie(cookie) {
  return Cookie.remove(cookie, { domain: import.meta.env.VITE_APP_DOMAIN });
}
