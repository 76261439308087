import router from '@/router';
import AuthenticationService from '@/services/authentication_service';
import { defineStore } from 'pinia'
import { getCookie, setCookie, removeCookie } from '@/config/cookie_config';
import { useToast } from 'vue-toast-notification';
const $toast = useToast();

export const useAuth = defineStore('auth', {
  state: () => ({
    returnUrl: null,
    isAuthenticated: !!getCookie('accessToken'),
    firstName: getCookie('firstName'),
    lastName: getCookie('lastName'),
    organizationName: getCookie('organizationName'),
    errorMessage: null,
  }),

  actions: {
    async login(body) {
      try {
        const response = await AuthenticationService.login(body)
        const response_data = response.data.data

        this.firstName = response_data.first_name
        this.lastName = response_data.last_name
        this.organizationName = response_data.organization_name
        setCookie('accessToken', response_data.session_token)
        setCookie('firstName', this.firstName)
        setCookie('lastName', this.lastName)
        setCookie('organizationName', this.organizationName)

        this.isAuthenticated = true;
        router.push({
          name: 'EmployeeList',
        });
      } catch (error) {
        $toast.clear();
        $toast.error(error.response.data.error.message, {
          position: 'top',
          dismissible: false,
        });
        return error
      }
    },
    logout() {
      this.firstName = ""
      this.lastName = ""
      this.organizationName = ""
      this.isAuthenticated = false;
      removeCookie('accessToken')
      removeCookie('firstName')
      removeCookie('lastName')
      removeCookie('organizationName')
      router.push({
        name: 'Login',
      });
    },
    async setPassword({ current_password, new_password }) {
      try {
        await AuthenticationService.setPassword({ current_password, new_password })
        router.push({ name: "SetPasswordSuccess" });
      } catch (error) {
        this.errorMessage = error.response.data.error.message
        return error
      }
    },
  },
})
