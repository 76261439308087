import { CORE_API } from '@/config/api_config';

const base_url = 'utilities'

export default {
  getSidebarParams() {
    const base = `${base_url}/sidebar`;
    return CORE_API.get(base);
  },
};
