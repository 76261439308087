import axios from 'axios';
import { getCookie } from '@/config/cookie_config';
import { useAuth } from '../stores/modules/authentication'
import router from '@/router';

const CORE_API_URL = import.meta.env.VITE_APP_CORE_API;
const API_KEY = import.meta.env.VITE_APP_API_KEY;

export const CORE_API = axios.create({
  baseURL: CORE_API_URL,
});

CORE_API.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + getCookie('accessToken');
  config.headers['X-API-KEY'] = API_KEY;
  return config;
});

CORE_API.interceptors.response.use(function onResponse(response) {
  return response;
}, async function onResponseError(error) {
    if (error.response.status === 401) { // can't handle
      const authStore = useAuth();
      authStore.isAuthenticated = false
      console.log(error)
      router.push({
        name: 'Login',
      });
    }
    return Promise.reject(error);
});

export function buildURL(base, queryParams) {
  const queryString = [];
  Object.entries(queryParams).forEach(([key, value]) => {
    if ((typeof value !== 'undefined' && value !== null) || value) queryString.push(`${key}=${value}`);
  });
  return `${base}?${queryString.join('&')}`;
}