import { CORE_API } from '@/config/api_config';

export default {
  login({
    mobileNumber,
    pin
  }) {
    const base = 'auth/login';
    const body = {
      data: {
        mobile_number_or_email: mobileNumber,
        password: pin,
      },
    }
    console.log(body)
    return CORE_API.post(base, body);
  },
  signUp(body) {
    const base = 'auth/signup';

    return CORE_API.post(base, body);
  },
  setPassword({ current_password, new_password }) {
    const base = 'auth/set_password';
    const body = {
      data: {
        current_password: current_password,
        new_password: new_password,
      },
    }

    return CORE_API.post(base, body);
  },
};
